export const GCLID = 'gclid';
export const CN = 'cn';
export const CD = 'cd';
export const CO = 'co';
export const CP = 'cp'
export const CO_CP_EXPIRY = 'co_cp_expiry';
export const CD_CN_EXPIRY = 'cd_cn_expiry';
export const GCLIDCOOKIE = 'lionardwebsite_gclid';
export const GCLIDCOOKIE_EXPIRY = 'lionardwebsite_gclid_expiry';
export const CNCOOKIE = 'lionardwebsite_cn'
export const CDCOOKIE = 'lionardwebsite_cd'
export const COCOOKIE = 'lionardwebsite_co'
export const CPCOOKIE = 'lionardwebsite_cp'
export const SESSIONKEYCOOKIE = 'lionardwebsite_sessionkey';
export const SESSIONKEYCOOKIE_EXPIRY = 'lionardwebsite_sessionkey_expiry';
export const PIXEL = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
export const SEOQUERYPARAMS = [
  { key: 'pagina', values: [] as any[], excludevalues: [0, 1], type: '+number' },
  { key: 'video', values: [1], excludevalues: [] as any[], type: '+number' },
  { key: 'rif', values: [], excludevalues: [] as any[], type: '+number' },
  { key: 'id', values: [], excludevalues: [] as any[], type: '+number' }
];
// non ho messo { key: 'ps', values: [], type: '+number' }
export const MIN_PERC_VIDEO_EVENT = 0.1;
export const GRADIENTS = {
  'en': '0deg, rgba(217, 9, 18, 0.7) 0%, rgba(217, 9, 18, 0.7) 100%',
  'es': '0deg, rgba(243, 146, 0, 0.85) 0%, rgba(243, 146, 0, 0.85) 100%',
  'fr': '0deg, rgba(0, 149, 219, 0.70) 0%, rgba(0, 149, 219, 0.70) 100%',
  'de': '0deg, rgba(253, 195, 0, 0.80) 0%, rgba(253, 195, 0, 0.80) 100%',
  'it': '0deg, rgba(131, 187, 38, 0.70) 0%, rgba(131, 187, 38, 0.70) 100%'

}
export const ELICHECKMETA = 'eli_check_meta';
export enum PageType {
  TAXONOMY = 'taxonomy',
  PRODUCT = 'product',
  DEAFULT = 'default'

}

export enum PagePrefix {
  TAXONOMY = 't',
  PRODUCT = 'p',
  DEFAULT = '',
}

export const NEW_PRODUCT_INTERVAL = 180 * 24 * 60 * 60 * 1000;
