import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-social',
  templateUrl: './social.component.' + environment.template + '.html',
  styleUrl: './social.component.scss'
})
export class SocialComponent {
  lang = environment.lang;
  fburl = environment.facebook;
  youtubelink = environment.youtubeplaylist;


}
