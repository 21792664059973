import { isPlatformBrowser } from '@angular/common';
import { AfterViewChecked, Component, ElementRef, EventEmitter, Inject, Input, OnChanges, Output, PLATFORM_ID, ViewChild, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-issuu-viewer',
  templateUrl: './issuu-viewer.component.html',
  styleUrl: './issuu-viewer.component.scss'
})
export class IssuuViewerComponent implements OnChanges, AfterViewInit {

  @Input()
  content: string;


  code: string;


  @ViewChild('viewerarea', {}) viewerarea: ElementRef;


  @Output() ready: EventEmitter<any> = new EventEmitter<any>();

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {

  }


  ngOnChanges() {
    this.setContent();
    this.showContent();


  }

  ngAfterViewInit(): void {
    this.showContent();
  }

  showContent() {
    if (isPlatformBrowser(this.platformId) && this.content && this.viewerarea) {

      const scriptEl = document.createRange().createContextualFragment(this.code);
      this.viewerarea.nativeElement.innerHTML = '';
      this.viewerarea.nativeElement.append(scriptEl);
      this.ready.emit();


    } else if (this.viewerarea) {

      this.viewerarea.nativeElement.innerHTML = '';
      this.ready.emit();
    }
  }



  setContent() {

    if (this.content) {
      this.code = '';
      if (this.content.startsWith('http')) {
        this.code = this.convertIssuuLinkToEmbedCode(this.content);
      } else {
        if (this.content) {
          this.code = ' <!--Inserito direttamente embed-->' + this.content;
        }
      }
    }

  }



  convertIssuuLinkToEmbedCode(link: string): string {
    // Crea un URL object per estrarre i parametri
    const url = new URL(link);

    // Estrarre il valore di "u" (utente) e "d" (documento) dal percorso
    const pathSegments = url.pathname.split('/');
    console.log(pathSegments);
    const username = pathSegments[1]; // utente (es: 'elipublishing')
    const documentId = pathSegments[3]; // documento (es: 'sfogliabile-ready-for-english')

    // Creare il codice embed
    const embedCode = `
    <!--Generato a partire da link-->
    <div style="width: 400px; height: 300px; max-width: 100%">
      <iframe
        src="https://e.issuu.com/embed.html?u=${username}&d=${documentId}"
        style="border:none;width:100%;height:100%;"
        allow="clipboard-write, allow-top-navigation, allow-top-navigation-by-user-activation, allow-downloads, allow-scripts, allow-same-origin, allow-popups, allow-modals, allow-popups-to-escape-sandbox, allow-forms"
        allowfullscreen="true">
      </iframe>
    </div>
  `;
    return embedCode;
  }

}
