import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { Book } from 'eli-ui-lib';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrl: './carousel.component.scss'
})
export class CarouselComponent implements OnInit {

  @Input()
  items: Book[];
  @Input() maxVisible: number = 4;
  @Input() numScroll: number = 1
  setFunction: any;
  mediaQueryList: MediaQueryList[] = [];
  numVisible: number;
  carouselReady: boolean;
  shownav: boolean;


  constructor(@Inject(PLATFORM_ID) private platformId: Object) {

  }
  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.initMediaQueries();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items && this.items?.length) {
      if (this.numVisible) this.updateCarousel();
      this.carouselReady = true;
    }
  }

  initMediaQueries() {
    const queries = [
      '(max-width: 767px)',
      '(min-width: 768px) and (max-width: 991px)',
      '(min-width: 992px) and (max-width: 1199px)',
      '(min-width: 1200px)'
    ];

    this.setFunction = this.setNum.bind(this);

    queries.forEach((query) => {
      const mediaQueryList = window.matchMedia(query);
      this.mediaQueryList.push(mediaQueryList);
      mediaQueryList.addEventListener('change', this.setFunction);
    });

    this.setNum();
  }

  removeMediaQueryListeners() {
    this.mediaQueryList.forEach((mediaQueryList) => {
      mediaQueryList.removeEventListener('change', this.setFunction);
    });
  }

  setNum() {
    const width = window.innerWidth;
    if (width < 768) {
      this.numVisible = 1;
    } else if (width < 992) {
      this.numVisible = this.maxVisible < 2 ? this.maxVisible : 2;
    } else if (width < 1200) {
      this.numVisible = this.maxVisible < 3 ? this.maxVisible : 3;
    } else {
      this.numVisible = this.maxVisible;
    }
    if (this.carouselReady) this.updateCarousel();
  }
  updateCarousel() {
    this.shownav = this.items?.length > this.numVisible;
  }
}
