import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { plainToInstance } from 'class-transformer';
import { Count, Page, Search } from 'elbuild-ui-lib-core';
import { Attribute, Book, BookInstance, BookResource, CatalogSearch, CMS, Taxonomy, CopyRequest } from 'eli-ui-lib';
import { EMPTY, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {



  httpOptions: any;

  constructor(protected http: HttpClient, private router: Router) { }


  //custom call per chiamata autocomplete teacher
  searchEntities(entityClass: any, entityName: string, search: Search, page: Page): Observable<any> {
    const s = new CatalogSearch();
    s.text = search.likeTmp['title'];
    s.start = page.start;
    s.stop = page.stop;
    s.teacheronly = true
    return this.searchProducts(s);
  }



  getTaxonomyByUrl(url: string): Observable<Taxonomy> {
    return this.http.get(`/ws/catalog/taxonomy/route/${url}`)
      .pipe(map(res => plainToInstance(Taxonomy, res)));
  }

  getTaxonomyById(taxonomyid: number) {
    return this.http.get(`/ws/taxonomy/${taxonomyid}/public`)
      .pipe(map(res => plainToInstance(Taxonomy, res)));
  }


  getCmsBySlug(slug: string): Observable<any> {
    return this.http.get(`/ws/cms/slug/${slug}`)
      .pipe(map(res => plainToInstance(CMS, res)));
  }

  getFeaturedProducts(): Observable<Book[]> {
    return this.http.get(`/ws/book/featured`)
      .pipe(map(res => plainToInstance(Book, res) as unknown as Book[]));
  }


  getProduct(url: string): Observable<Book> {
    return this.http.get(`/ws/catalog/book/${url}`)
      .pipe(map((res: any) => {
        if (!res) {
          this.router.navigateByUrl('/404');
          return undefined;
        }
        else if (res.code === 301) {
          this.router.navigateByUrl('/');
          return undefined;
        }
        return plainToInstance(Book, res);

      }));
  }

  getBookResources(bookid: number): Observable<BookResource[]> {
    return this.http.get(`/ws/bookresource/book/${bookid}`)
      .pipe(map(res => plainToInstance(BookResource, res) as unknown as BookResource[]));
  }


  getBookResourcesDisclosed(bookid: number): Observable<BookResource[]> {
    return this.http.get(`/ws/bookresource/book/${bookid}/disclosed`)
      .pipe(map(res => plainToInstance(BookResource, res) as unknown as BookResource[]));
  }

  getBookCorrelated(bookid: number): Observable<Book[]> {
    return this.http.get(`/ws/book/${bookid}/correlated`)
      .pipe(map(res => plainToInstance(Book, res) as unknown as Book[]));
  }

  getTeacherResources(): Observable<BookResource[]> {
    return this.http.get(`/ws/bookresource/disclosed`)
      .pipe(map(res => plainToInstance(BookResource, res) as unknown as BookResource[]));
  }

  getToolboxResources(): Observable<CMS[]> {
    return this.http.get(`/ws/cms/type/toolbox`)
      .pipe(map(res => plainToInstance(CMS, res) as unknown as CMS[]));
  }

  getBookInstances(bookid: number): Observable<BookInstance[]> {
    return this.http.get(`/ws/bookinstance/book/${bookid}`)
      .pipe(map(res => plainToInstance(BookInstance, res) as unknown as BookInstance[]));
  }
  getAttributes(): Observable<Attribute[]> {
    return this.http.get(`/ws/attribute/public`)
      .pipe(map(res => plainToInstance(Attribute, res) as unknown as Attribute[]));
  }

  getTaxonomyTree(): Observable<Taxonomy[]> {
    return this.http.get(`/ws/taxonomy/fulltree/public`, {})
      .pipe(map(res => plainToInstance(Taxonomy, res) as unknown as Taxonomy[]));
  }

  searchProducts(search: CatalogSearch): Observable<Book[]> {
    return this.http.post(`/ws/catalog/search`, search)
      .pipe(map(res => plainToInstance(Book, res) as unknown as Book[]));
  }

  getTaxonomyProducts(id: number, size: number): Observable<Book[]> {
    const search = new CatalogSearch();
    search.taxonomyid = id;
    search.start = 0;
    search.stop = size;
    return this.http.post(`/ws/catalog/search`, search)
      .pipe(map(res => plainToInstance(Book, res) as unknown as Book[]));
  }

  countProducts(search: CatalogSearch): Observable<Count> {
    return this.http.post(`/ws/catalog/count`, search)
      .pipe(map(res => plainToInstance(Count, res)));
  }


  requestCopy(r: CopyRequest): Observable<any> {
    return this.http.post(`/ws/sample/request`, r);
  }

}
