import { isPlatformBrowser } from '@angular/common';
import { Input, OnInit, Directive, OnChanges, ElementRef, Renderer2, Inject, PLATFORM_ID } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MediaDownloadService } from 'elbuild-ui-lib-core';
import { CatalogService } from '../services/catalog.service';
import { CMS } from 'eli-ui-lib';


@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[cmsbgr]',
})

export class CmsBackgroundDirective implements OnChanges, OnInit {




  @Input() slug: string;

  @Input() placeholder: string;

  @Input() thumb: string;


  @Input() size: string; // xl, lg, md, xs

  @Input() cdn: string;

  @Input() folder: string; // usato solo se cdn attiva

  @Input() base: string; // usato solo se cdn attiva


  isready: boolean;


  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private sanitizer: DomSanitizer,
    public assets_service: MediaDownloadService,
    private el: ElementRef,
    private renderer: Renderer2,
    private catalogservice: CatalogService
  ) {
  }

  ngOnInit() {
    if (this.placeholder && !this.isready) {
      this.renderer.setStyle(this.el.nativeElement, 'background-image', 'url(' + this.placeholder + ')');

    }

  }
  ngOnChanges() {
    if (isPlatformBrowser(this.platformId)) {
      this.loadCms();
    }
  }

  loadCms() {
    if (this.slug) {
      this.catalogservice.getCmsBySlug(this.slug).subscribe({
        next: (data: CMS) => {
          if (data?.media) {
            this.loadImage(data?.media);
          }
        },
        error: (error: any) => {
          console.error('error', error);
        }
      });
    }
  }


  loadImage(opaque: string) {
    if (this.cdn) {
      const cdnurl = this.createCDNUrl(this.cdn, this.base, this.folder, opaque, this.size, undefined);
      if (cdnurl) {
        const url = 'url("' + cdnurl + '")';
        this.renderer.setStyle(this.el.nativeElement, 'background-image', url);
        this.isready = true;

      }
    } else if (opaque) {
      this.assets_service.getMediaOpaque(opaque, this.size).subscribe(data => {
        if (data && data.status !== 204) {

          try {
            const url = 'url("' + URL.createObjectURL(data.body || data) + '")';
            this.renderer.setStyle(this.el.nativeElement, 'background-image', url);
            this.isready = true;
          } catch (error) {
            console.log(error);

          }
        }
      }, error => {
        console.log('%c error', 'color:#FFB86C', error);
      });

    }
  }
  createCDNUrl(cdn: string, base: string, folder: string, opaque: string, size: string, cdntype: string): string {
    throw new Error('Method not implemented.');
  }





}

