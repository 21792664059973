import { isPlatformBrowser } from '@angular/common';
import { Input, OnInit, Directive, OnChanges, ElementRef, Renderer2, Inject, PLATFORM_ID, input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { createCDNUrl, MediaDownloadService } from 'elbuild-ui-lib-core';
import { Taxonomy } from 'eli-ui-lib';
import { GRADIENTS } from 'src/conf/constant';
import { environment } from 'src/environments/environment';


@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[asyncbggradient]',
})

export class AsyncBackgroundGradientDirective implements OnChanges, OnInit {


  @Input() id: number;

  @Input() placeholder: string;

  @Input() thumb: string;

  @Input() opaque: string;

  @Input() size: string; // xl, lg, md, xs

  @Input() cdn: string = environment.cdnurl;

  @Input() folder: string; // usato solo se cdn attiva

  @Input() base: string = environment.cdnforlder;// usato solo se cdn attiva

  @Input() taxonomy: Taxonomy;

  style: string;




  isready: boolean;



  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private sanitizer: DomSanitizer,
    public assets_service: MediaDownloadService,
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    if (GRADIENTS[environment.lang]) {
      this.style = `linear-gradient(${GRADIENTS[environment.lang]}), url(''), url('') lightgray 50% / cover no-repeat`;
    }
  }

  ngOnInit() {
    if (this.placeholder && !this.isready) {
      this.applyStyle(this.placeholder);

    }




  }

  ngOnChanges() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.taxonomy) {
        this.id = this.cdn ? this.taxonomy.id : undefined;
        this.folder = 'taxonomy';
        this.opaque = this.taxonomy.cover;

      }
      if (this.cdn) {
        const cdnurl = createCDNUrl(this.cdn, this.base, this.folder, this.id, this.opaque, this.size);
        if (cdnurl) {
          this.applyStyle(cdnurl);
          this.isready = true;
        }
      } else if (this.opaque) {
        this.assets_service.getMediaOpaque(this.opaque, this.size).subscribe(data => {
          if (data && data.status !== 204) {

            try {
              this.applyStyle(URL.createObjectURL(data.body || data));
              this.isready = true;
            } catch (error) {
              console.log(error);

            }
          }
        }, error => {
          console.log(error);
        });

      } else if (this.id && this.thumb) {
        this.assets_service.getThumb(this.id, this.thumb).subscribe(data => {
          if (data && data.status !== 204) {

            try {
              this.applyStyle(URL.createObjectURL(data.body || data));
              this.isready = true;
            } catch (error) {
              console.log(error);

            }

          }
        }, error => {
          console.log(error);
        });

      }
    }
  }


  private applyStyle(url: string) {
    let s: string;
    let u = `url('${url}')`;
    if (this.style) {
      s = this.style.replaceAll(`url('')`, u);
      this.renderer.setStyle(this.el.nativeElement, 'background', s);
    } else {
      this.renderer.setStyle(this.el.nativeElement, 'background-image', u);
    }

  }

}

