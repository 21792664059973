import { Component, Input } from '@angular/core';
import { Book } from 'eli-ui-lib';
import { NEW_PRODUCT_INTERVAL } from 'src/conf/constant';

@Component({
  selector: 'app-catalog-item',
  templateUrl: './catalog-item.component.html',
  styleUrl: './catalog-item.component.scss'
})
export class CatalogItemComponent {

  @Input()
  item: Book;
  newmininsdate: any;


  constructor() {
    const now = new Date().getTime();
    this.newmininsdate = now - NEW_PRODUCT_INTERVAL;
  }
}
