import 'reflect-metadata';
import * as i0 from '@angular/core';
import { EventEmitter, Component, Input, Output, NgModule } from '@angular/core';
import * as i1 from '@angular/router';
import { RouterModule } from '@angular/router';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import { Staff, boolTransform, dateTransform, tolowercaseTransform, currencyTransform, jsonTransform } from 'elbuild-ui-lib-core';
import { __decorate } from 'tslib';
import { Type, Transform, Exclude, Expose } from 'class-transformer';
import { isEqual } from 'lodash';
const _forTrack0 = ($index, $item) => $item.id;
const _c0 = (a0, a1) => ({
  node: a0,
  index: a1,
  level: 0
});
const _c1 = a0 => ({
  node: a0
});
const _c2 = (a0, a1, a2) => ({
  node: a0,
  index: a1,
  level: a2
});
function TreeViewComponent_For_1_1_ng_template_0_Template(rf, ctx) {}
function TreeViewComponent_For_1_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TreeViewComponent_For_1_1_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function TreeViewComponent_For_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵtemplate(1, TreeViewComponent_For_1_1_Template, 1, 0, null, 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r1 = ctx.$implicit;
    const i_r2 = ctx.$index;
    i0.ɵɵnextContext();
    const treenode_r3 = i0.ɵɵreference(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", treenode_r3)("ngTemplateOutletContext", i0.ɵɵpureFunction2(2, _c0, item_r1, i_r2));
  }
}
function TreeViewComponent_ng_template_2_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 7);
    i0.ɵɵlistener("click", function TreeViewComponent_ng_template_2_Conditional_1_Template_span_click_0_listener() {
      i0.ɵɵrestoreView(_r4);
      const node_r5 = i0.ɵɵnextContext().node;
      const ctx_r5 = i0.ɵɵnextContext();
      return i0.ɵɵresetView((ctx_r5.expanded[node_r5.id] = !ctx_r5.expanded[node_r5.id]));
    });
    i0.ɵɵelement(1, "i");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const node_r5 = i0.ɵɵnextContext().node;
    const ctx_r5 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵclassMap(ctx_r5.expanded[node_r5.id] ? ctx_r5.iconexpanded : ctx_r5.iconcollapsed);
  }
}
function TreeViewComponent_ng_template_2_Conditional_2_For_2_1_ng_template_0_Template(rf, ctx) {}
function TreeViewComponent_ng_template_2_Conditional_2_For_2_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TreeViewComponent_ng_template_2_Conditional_2_For_2_1_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function TreeViewComponent_ng_template_2_Conditional_2_For_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵtemplate(1, TreeViewComponent_ng_template_2_Conditional_2_For_2_1_Template, 1, 0, null, 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r7 = ctx.$implicit;
    const i_r8 = ctx.$index;
    const level_r9 = i0.ɵɵnextContext(2).level;
    i0.ɵɵnextContext();
    const treenode_r3 = i0.ɵɵreference(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", treenode_r3)("ngTemplateOutletContext", i0.ɵɵpureFunction3(2, _c2, item_r7, i_r8, level_r9 + 1));
  }
}
function TreeViewComponent_ng_template_2_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵrepeaterCreate(1, TreeViewComponent_ng_template_2_Conditional_2_For_2_Template, 2, 6, "div", 2, _forTrack0);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r9 = i0.ɵɵnextContext();
    const node_r5 = ctx_r9.node;
    const level_r9 = ctx_r9.level;
    i0.ɵɵclassMapInterpolate1("node-children ms-", (level_r9 + 2) * 2, "");
    i0.ɵɵadvance();
    i0.ɵɵrepeater(node_r5.children);
  }
}
function TreeViewComponent_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0, 4);
    i0.ɵɵtemplate(1, TreeViewComponent_ng_template_2_Conditional_1_Template, 2, 3, "span", 5)(2, TreeViewComponent_ng_template_2_Conditional_2_Template, 3, 3, "div", 6);
  }
  if (rf & 2) {
    const node_r5 = ctx.node;
    const ctx_r5 = i0.ɵɵnextContext();
    const nodecontent_r11 = i0.ɵɵreference(5);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r5.template || nodecontent_r11)("ngTemplateOutletContext", i0.ɵɵpureFunction1(4, _c1, node_r5));
    i0.ɵɵadvance();
    i0.ɵɵconditional(1, (node_r5.children == null ? null : node_r5.children.length) ? 1 : -1);
    i0.ɵɵadvance();
    i0.ɵɵconditional(2, ctx_r5.expanded[node_r5.id] && (node_r5.children == null ? null : node_r5.children.length) ? 2 : -1);
  }
}
function TreeViewComponent_ng_template_4_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r12 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 10);
    i0.ɵɵlistener("click", function TreeViewComponent_ng_template_4_Conditional_0_Template_a_click_0_listener() {
      i0.ɵɵrestoreView(_r12);
      const node_r13 = i0.ɵɵnextContext().node;
      const ctx_r5 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r5.click(node_r13));
    });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const node_r13 = i0.ɵɵnextContext().node;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", node_r13.label, " ");
  }
}
function TreeViewComponent_ng_template_4_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r14 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 11);
    i0.ɵɵlistener("click", function TreeViewComponent_ng_template_4_Conditional_1_Template_a_click_0_listener() {
      i0.ɵɵrestoreView(_r14);
      const node_r13 = i0.ɵɵnextContext().node;
      const ctx_r5 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r5.click(node_r13));
    });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const node_r13 = i0.ɵɵnextContext().node;
    const ctx_r5 = i0.ɵɵnextContext();
    i0.ɵɵproperty("routerLink", ctx_r5.basepath + node_r13.routerlink);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", node_r13.label, " ");
  }
}
function TreeViewComponent_ng_template_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TreeViewComponent_ng_template_4_Conditional_0_Template, 2, 1, "a", 8)(1, TreeViewComponent_ng_template_4_Conditional_1_Template, 2, 2, "a", 9);
  }
  if (rf & 2) {
    const node_r13 = ctx.node;
    const ctx_r5 = i0.ɵɵnextContext();
    i0.ɵɵconditional(0, !node_r13.routerlink || ctx_r5.basepath === undefined ? 0 : -1);
    i0.ɵɵadvance();
    i0.ɵɵconditional(1, node_r13.routerlink && ctx_r5.basepath ? 1 : -1);
  }
}
class TreeViewComponent {
  constructor() {
    this.basepath = '/';
    this.iconexpanded = 'fas fa-chevron-down';
    this.iconcollapsed = 'fas fa-chevron-right';
    this.expanded = {};
    this.clicked = new EventEmitter();
  }
  ngOnInit() {
    if (this.expandednodes) {
      for (const e of this.expandednodes) {
        this.expanded[e] = true;
      }
    }
  }
  click(item) {
    this.clicked.emit(item);
  }
  static {
    this.ɵfac = function TreeViewComponent_Factory(t) {
      return new (t || TreeViewComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TreeViewComponent,
      selectors: [["app-tree-view"]],
      inputs: {
        roots: "roots",
        basepath: "basepath",
        expandednodes: "expandednodes",
        iconexpanded: "iconexpanded",
        iconcollapsed: "iconcollapsed",
        template: "template"
      },
      outputs: {
        clicked: "clicked"
      },
      decls: 6,
      vars: 0,
      consts: [["treenode", ""], ["nodecontent", ""], [1, "node-item"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"], [3, "ngTemplateOutlet", "ngTemplateOutletContext"], [1, "icon-container"], [3, "class"], [1, "icon-container", 3, "click"], [1, "link"], [1, "link", 3, "routerLink"], [1, "link", 3, "click"], [1, "link", 3, "click", "routerLink"]],
      template: function TreeViewComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵrepeaterCreate(0, TreeViewComponent_For_1_Template, 2, 5, "div", 2, _forTrack0);
          i0.ɵɵtemplate(2, TreeViewComponent_ng_template_2_Template, 3, 6, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor)(4, TreeViewComponent_ng_template_4_Template, 2, 2, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
        }
        if (rf & 2) {
          i0.ɵɵrepeater(ctx.roots);
        }
      },
      dependencies: [i1.RouterLink, i2.NgTemplateOutlet]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TreeViewComponent, [{
    type: Component,
    args: [{
      selector: 'app-tree-view',
      template: "\n@for (item of roots; track item.id; let i=$index) {\n    <div class=\"node-item\">\n<ng-template *ngTemplateOutlet=\"treenode; context: {node: item, index: i, level: 0}\"></ng-template>\n</div>\n}\n\n<ng-template\n\t#treenode\n\tlet-node=\"node\"\n\tlet-index=\"index\"\n    let-level=\"level\">\n\n<ng-container\n    [ngTemplateOutlet]=\"template || nodecontent\"\n    [ngTemplateOutletContext]=\"{node: node}\"\n  ></ng-container>\n\n    @if (node.children?.length) {\n    <span class=\"icon-container\" (click)=\"expanded[node.id] = !expanded[node.id]\">\n\t\t<i\n    class=\"{{expanded[node.id] ? iconexpanded: iconcollapsed}}\"\n\t\t\t></i>\n\t</span>\n    }\n    @if (expanded[node.id] && node.children?.length) {\n    <div class=\"node-children ms-{{(level+2)*2}}\" >\n        @for (item of node.children; track item.id; let i=$index) {\n\n         <div class=\"node-item\">\n            <ng-template *ngTemplateOutlet=\"treenode; context: {node: item, index: i, level: level+1}\"></ng-template>\n        </div>\n        }\n    </div>\n    }\n</ng-template>\n\n<ng-template\n\t#nodecontent\n\tlet-node=\"node\"\n>\n\n\n    @if (!node.routerlink || basepath === undefined) {\n\t<a\n    (click)=\"click(node)\"\n\t\tclass=\"link\"\n\t\t>\n\t\t{{ node.label }}\n\t</a>\n    }\n\n    @if (node.routerlink && basepath) {\n    <a\n        (click)=\"click(node)\"\n        [routerLink]=\"basepath+node.routerlink\"\n\t\tclass=\"link\"\n\t\t>\n\t\t{{ node.label }}\n\t</a>\n    }\n</ng-template>\n"
    }]
  }], null, {
    roots: [{
      type: Input
    }],
    basepath: [{
      type: Input
    }],
    expandednodes: [{
      type: Input
    }],
    iconexpanded: [{
      type: Input
    }],
    iconcollapsed: [{
      type: Input
    }],
    template: [{
      type: Input
    }],
    clicked: [{
      type: Output
    }]
  });
})();
class EliUILibModule {
  static {
    this.ɵfac = function EliUILibModule_Factory(t) {
      return new (t || EliUILibModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: EliUILibModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [RouterModule, CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EliUILibModule, [{
    type: NgModule,
    args: [{
      declarations: [TreeViewComponent],
      imports: [RouterModule, CommonModule],
      exports: [TreeViewComponent]
    }]
  }], null, null);
})();
var EliUserStatus;
(function (EliUserStatus) {
  EliUserStatus["ACTIVE"] = "active";
  EliUserStatus["INACTIVE"] = "inactive";
})(EliUserStatus || (EliUserStatus = {}));
class EliUser extends Staff {
  constructor() {
    super(...arguments);
    this.lang = 'IT';
  }
}
var AttributeType;
(function (AttributeType) {
  AttributeType["HEX"] = "hex";
  AttributeType["STRING"] = "string";
  AttributeType["NUMBER"] = "number";
  AttributeType["DATE"] = "date";
  AttributeType["WYSIWYG"] = "wysiwyg";
})(AttributeType || (AttributeType = {}));
var AttributeInpuType;
(function (AttributeInpuType) {
  AttributeInpuType["INPUT"] = "input";
  AttributeInpuType["SELECT"] = "select";
})(AttributeInpuType || (AttributeInpuType = {}));
var AttributeRenderType;
(function (AttributeRenderType) {
  AttributeRenderType["DEFAULT"] = "default";
  AttributeRenderType["PREVIEW"] = "preview";
  AttributeRenderType["ATTACHMENT"] = "attachment";
  AttributeRenderType["HIDDEN"] = "hidden";
})(AttributeRenderType || (AttributeRenderType = {}));
class Attribute {
  constructor() {
    this.attrtype = AttributeType.STRING;
    this.rendertype = AttributeRenderType.DEFAULT;
    this.vals = [];
    this.configurable = false;
  }
  get isSelect() {
    return this.inputtype === AttributeInpuType.SELECT;
  }
  get isInput() {
    return this.inputtype === AttributeInpuType.INPUT;
  }
  get isHex() {
    return this.attrtype === AttributeType.HEX;
  }
  get isString() {
    return this.attrtype === AttributeType.STRING;
  }
  get isNumber() {
    return this.attrtype === AttributeType.NUMBER;
  }
  get isDate() {
    return this.attrtype === AttributeType.DATE;
  }
  get isWysiwyg() {
    return this.attrtype === AttributeType.WYSIWYG;
  }
}
__decorate([Type(attributeval)], Attribute.prototype, "vals", void 0);
__decorate([Transform(boolTransform)], Attribute.prototype, "configurable", void 0);
__decorate([Transform(boolTransform)], Attribute.prototype, "comparable", void 0);
__decorate([Transform(boolTransform)], Attribute.prototype, "featured", void 0);
__decorate([Transform(dateTransform)], Attribute.prototype, "insdate", void 0);
__decorate([Transform(dateTransform)], Attribute.prototype, "moddate", void 0);
function attribute() {
  return Attribute;
}
class AttributeVal {
  get anchor() {
    return this.val ? this.val.replace(/[^a-zA-Z0-9]/g, '') : '';
  }
}
__decorate([Transform(dateTransform)], AttributeVal.prototype, "insdate", void 0);
__decorate([Transform(dateTransform)], AttributeVal.prototype, "moddate", void 0);
__decorate([Exclude()], AttributeVal.prototype, "attribute", void 0);
function attributeval() {
  return AttributeVal;
}
var TranslateStatus;
(function (TranslateStatus) {
  TranslateStatus["STATUS_OK"] = "ok";
  TranslateStatus["STATUS_KO"] = "no";
})(TranslateStatus || (TranslateStatus = {}));
function parentZeroToUndefined(params) {
  return params.value ? params.value : undefined;
}
function parentUndefinedToZero(params) {
  return params.value ? params.value : 0;
}
//@dynamic
class Taxonomy {
  set tags(s) {
    if (s) {
      this._tags = s.split(':');
    } else {
      this._tags = [];
    }
  }
  get tags() {
    return this._tags ? this._tags.join(':') : undefined;
  }
  get separatedtags() {
    return this._tags;
  }
  set separatedtags(v) {
    this._tags = v || [];
  }
  get routerlink() {
    return this.url;
  }
  get metatitle() {
    return this.pagetitle || this.name;
  }
  get metaimage() {
    return this.cover;
  }
  get path() {
    return this.url ? '/shop/collection/' + this.url : '#';
  }
  get slug() {
    return this.url;
  }
  get metakeyword() {
    return "";
  }
  get canonical() {
    return undefined;
  }
  get nodechildren() {
    return this.children;
  }
  get label() {
    return this.name;
  }
  constructor() {
    this._tags = [];
    this.parent = null;
  }
  addTag(s) {
    if (!this.separatedtags) {
      this.separatedtags = [];
    }
    this.separatedtags.push(s);
  }
  removeTag(index) {
    if (this.separatedtags.length > index) {
      this.separatedtags.splice(index, 1);
    }
  }
}
__decorate([Type(attribute)], Taxonomy.prototype, "attributes", void 0);
__decorate([Transform(tolowercaseTransform)], Taxonomy.prototype, "url", void 0);
__decorate([Transform(parentZeroToUndefined, {
  toPlainOnly: true
}), Transform(parentUndefinedToZero, {
  toClassOnly: true
})], Taxonomy.prototype, "parent", void 0);
__decorate([Transform(dateTransform)], Taxonomy.prototype, "insdate", void 0);
__decorate([Transform(dateTransform)], Taxonomy.prototype, "moddate", void 0);
__decorate([Type(taxonomy)], Taxonomy.prototype, "children", void 0);
__decorate([Expose({
  name: 'tags'
})], Taxonomy.prototype, "tags", null);
function taxonomy() {
  return Taxonomy;
}
//FIXME serve? fose si era ipotizzato la possibilità di fargli scegliere quali filtri apparire nelle pagine
class TaxonomyAttribute {}
__decorate([Type(attribute)], TaxonomyAttribute.prototype, "attribute", void 0);
class Entity {}
__decorate([Transform(dateTransform)], Entity.prototype, "insdate", void 0);
__decorate([Transform(dateTransform)], Entity.prototype, "moddate", void 0);
__decorate([Transform(dateTransform)], Entity.prototype, "lastdate", void 0);
class NamedEntity extends Entity {}
class FullNamedEntity extends Entity {
  get fullname() {
    return `${this.fname} ${this.lname}`;
  }
}
class Vendor extends Entity {}
function vendor() {
  return Vendor;
}

// Products
//@dynamic
class Book {
  static {
    this.TABS = ['elionline', 'sampledownload', 'digitalbookdemolink', 'tocopaqueid', 'brochuredownload', 'webinar', 'videolesson', 'pptopaqueid'];
  }
  setResources(data) {
    if (data) {
      const samples = [];
      let privateresources = {};
      let disclosedresources = {};
      for (const r of data) {
        if (r.brochure) {
          this.brochuredownload = r;
        } else if (r.resourcetype === BookResourceType.ISSUU) {
          samples.push(r);
        } else {
          if (!r.disclosed) {
            privateresources = this.addResourceTo(r, privateresources);
          } else {
            disclosedresources = this.addResourceTo(r, disclosedresources);
          }
        }
      }
      if (samples && samples.length) {
        this.sampledownload = samples;
      }
      if (disclosedresources && Object.keys(disclosedresources).length) {
        this.disclosedresources = disclosedresources;
        this.hasdisclosedresources = true;
      }
      if (privateresources && Object.keys(privateresources).length) {
        this.privateresources = privateresources;
      }
    }
  }
  addResourceTo(r, resources) {
    const group1 = r.group1 || 'undefined';
    const group2 = r.group2 || 'undefined';
    if (!resources[group1]) {
      resources[group1] = {};
    }
    if (!resources[group1][group2]) {
      resources[group1][group2] = [];
    }
    resources[group1][group2].push(r);
    return resources;
  }
  get metatitle() {
    return this.pagetitle || this.title;
  }
  get metaimage() {
    return this.cover;
  }
  get metakeyword() {
    return "";
  }
  get canonical() {
    return undefined;
  }
  get slug() {
    return this.url;
  }
  get hasonlinematerial() {
    return Book.TABS.find(s => s !== 'digitalbookdemolink' && this[s] != undefined && this[s] != '') !== undefined;
  }
  get elionline() {
    const bt = this.elionlinelink ? this.elionlinelink.replace('https://www.youtube.com/watch?v=', '') : undefined;
    return bt;
  }
  get webinar() {
    const bt = this.webinarlink ? this.webinarlink.replace('https://www.youtube.com/watch?v=', '') : undefined;
    return bt;
  }
  get videolesson() {
    const bt = this.videolessonlink ? this.videolessonlink.replace('https://www.youtube.com/watch?v=', '') : undefined;
    return bt;
  }
  constructor() {
    this.today = new Date();
  }
}
__decorate([Transform(tolowercaseTransform)], Book.prototype, "url", void 0);
__decorate([Transform(dateTransform)], Book.prototype, "insdate", void 0);
__decorate([Transform(dateTransform)], Book.prototype, "moddate", void 0);
__decorate([Transform(dateTransform)], Book.prototype, "deletedate", void 0);
__decorate([Type(taxonomy)], Book.prototype, "activetaxonomies", void 0);
__decorate([Exclude()], Book.prototype, "brochuredownload", void 0);
__decorate([Exclude()], Book.prototype, "sampledownload", void 0);
__decorate([Exclude()], Book.prototype, "disclosedresources", void 0);
__decorate([Exclude()], Book.prototype, "privateresources", void 0);
__decorate([Exclude()], Book.prototype, "hasresources", void 0);
__decorate([Exclude()], Book.prototype, "hasdisclosedresources", void 0);
class BookInstance extends Entity {}
__decorate([Transform(currencyTransform)], BookInstance.prototype, "price", void 0);
__decorate([Transform(dateTransform)], BookInstance.prototype, "deletedate", void 0);
class BookAttribute extends Entity {
  get isHex() {
    return this.type === AttributeType.HEX;
  }
  get isString() {
    return this.type === AttributeType.STRING;
  }
  get isNumber() {
    return this.type === AttributeType.NUMBER;
  }
  get isDate() {
    return this.type === AttributeType.DATE;
  }
  get isWysiwyg() {
    return this.type === AttributeType.WYSIWYG;
  }
}
__decorate([Type(attributeval)], BookAttribute.prototype, "valobj", void 0);
__decorate([Transform(boolTransform)], BookAttribute.prototype, "featured", void 0);
function productAttribute() {
  return BookAttribute;
}
class ProductMedia /*implements IAlbum*/ {}
__decorate([Transform(boolTransform)], ProductMedia.prototype, "cover", void 0);
__decorate([Transform(dateTransform)], ProductMedia.prototype, "insdate", void 0);
__decorate([Transform(dateTransform)], ProductMedia.prototype, "moddate", void 0);
__decorate([Exclude()], ProductMedia.prototype, "ready", void 0);
function product() {
  return Book;
}
function productMedia() {
  return ProductMedia;
}
// DEMO
class Variants {}
class Images {}
var RelType;
(function (RelType) {
  RelType["CORRELATED"] = "correlated";
  RelType["ALTERNATIVE"] = "alternative";
})(RelType || (RelType = {}));
class Correlated {
  constructor(productid, correlatedid) {
    this.productid = productid;
    this.correlatedid = correlatedid;
  }
}
__decorate([Transform(dateTransform)], Correlated.prototype, "insdate", void 0);
__decorate([Transform(dateTransform)], Correlated.prototype, "moddate", void 0);
__decorate([Type(product)], Correlated.prototype, "correlated", void 0);
//TODO pulire roba che non serve
class ProductVendor extends Entity {}
__decorate([Transform(currencyTransform)], ProductVendor.prototype, "purchaseprice", void 0);
__decorate([Type(product), Exclude({
  toPlainOnly: true
})], ProductVendor.prototype, "product", void 0);
__decorate([Type(vendor), Exclude({
  toPlainOnly: true
})], ProductVendor.prototype, "vendor", void 0);
class ShareRequest {}
class PriceElement {}
__decorate([Transform(currencyTransform)], PriceElement.prototype, "price", void 0);
__decorate([Transform(currencyTransform)], PriceElement.prototype, "taxes", void 0);
__decorate([Transform(currencyTransform)], PriceElement.prototype, "total", void 0);
__decorate([Transform(boolTransform)], PriceElement.prototype, "custompricing", void 0);
__decorate([Transform(currencyTransform)], PriceElement.prototype, "baseprice", void 0);
class ProductAttributeval {}
__decorate([Type(attributeval)], ProductAttributeval.prototype, "vals", void 0);
var BookResourceType;
(function (BookResourceType) {
  BookResourceType["ISSUU"] = "issuu";
  BookResourceType["YOUTUBE"] = "youtube";
  BookResourceType["LINK"] = "link";
  BookResourceType["OTHER"] = "other";
})(BookResourceType || (BookResourceType = {}));
class BookResource {
  constructor() {
    this.disclosed = false;
    this.sort = 0;
  }
}
__decorate([Transform(boolTransform)], BookResource.prototype, "disclosed", void 0);
__decorate([Transform(dateTransform)], BookResource.prototype, "insdate", void 0);
__decorate([Transform(dateTransform)], BookResource.prototype, "moddate", void 0);
__decorate([Transform(dateTransform)], BookResource.prototype, "deletedate", void 0);
__decorate([Type(book)], BookResource.prototype, "book", void 0);
class BookResourceGroup1 {}
class BookResourceGroup2 {}
function book() {
  return Book;
}
var CMSType;
(function (CMSType) {
  CMSType["BANNER"] = "banner";
  CMSType["IMAGE"] = "image";
  CMSType["CATALOG"] = "catalog";
  CMSType["WEBINAR"] = "webinar";
  CMSType["CMS"] = "cms";
  CMSType["AWARD"] = "award";
  CMSType["TOOLBOX"] = "toolbox";
})(CMSType || (CMSType = {}));
var CMSToolboxType;
(function (CMSToolboxType) {
  CMSToolboxType["PDF"] = "pdf";
  CMSToolboxType["ZIP"] = "zip";
  CMSToolboxType["LINK"] = "link";
  CMSToolboxType["AUDIO"] = "audio";
  CMSToolboxType["DOC"] = "doc";
  CMSToolboxType["IMAGE"] = "image";
  CMSToolboxType["VIDEO"] = "video";
})(CMSToolboxType || (CMSToolboxType = {}));
var CMSCatalogType;
(function (CMSCatalogType) {
  CMSCatalogType["PDF"] = "pdf";
  CMSCatalogType["LINK"] = "link";
})(CMSCatalogType || (CMSCatalogType = {}));
var CMSCMSType;
(function (CMSCMSType) {
  CMSCMSType["TEXTIMAGE"] = "textimage";
  CMSCMSType["TEXTONLY"] = "textonly";
})(CMSCMSType || (CMSCMSType = {}));
class CMS extends Entity {
  constructor() {
    super();
    this.wysiwyg = false;
  }
  get anchor() {
    if (this.attributeval && this.attributeval.val) {
      return this.attributeval.val.replace(/[^a-zA-Z0-9]/g, '');
    } else return this.slug;
  }
  get anchorlabel() {
    if (this.attributeval && this.attributeval.label) {
      return this.attributeval.label;
    } else return this.title;
  }
  get islink() {
    return (this.cmstype === CMSType.TOOLBOX || this.cmstype === CMSType.CATALOG) && this.resourcetype === CMSToolboxType.LINK;
  }
  get resourcetype() {
    return this.subtitle;
  }
  get toolboxtype() {
    return this.resourcetype;
  }
}
__decorate([Transform(boolTransform)], CMS.prototype, "wysiwyg", void 0);
__decorate([Transform(boolTransform)], CMS.prototype, "showtitle", void 0);
__decorate([Transform(dateTransform)], CMS.prototype, "date", void 0);
__decorate([Type(attributeval)], CMS.prototype, "attributeval", void 0);
function cms() {
  return CMS;
}
class CMSSection {}
class BookTaxonomy {
  get name() {
    return this.taxonomy ? this.taxonomy.name : undefined;
  }
  get url() {
    return this.taxonomy ? this.taxonomy.url : undefined;
  }
  get bookname() {
    return this.book ? this.book.title : undefined;
  }
  get bookactive() {
    return this.book ? this.book.active : undefined;
  }
}
__decorate([Transform(dateTransform)], BookTaxonomy.prototype, "insdate", void 0);
var StorefrontExtraFeeType;
(function (StorefrontExtraFeeType) {
  StorefrontExtraFeeType["PERCENTAGE"] = "percentage";
  StorefrontExtraFeeType["VALUE"] = "value";
})(StorefrontExtraFeeType || (StorefrontExtraFeeType = {}));
class StoreFront {
  get dante() {
    if (this.confs && this.confs['dante']) {
      return this.confs['dante'];
    }
    return undefined;
  }
  get gradedreaders() {
    if (this.confs && this.confs['gradedreaders']) {
      return this.confs['gradedreaders'];
    }
    return undefined;
  }
}
__decorate([Transform(jsonTransform)], StoreFront.prototype, "confs", void 0);
class Storefrontlang {}
__decorate([Transform(dateTransform)], Storefrontlang.prototype, "insdate", void 0);
__decorate([Transform(dateTransform)], Storefrontlang.prototype, "moddate", void 0);
class Userstorefront {}
__decorate([Transform(dateTransform)], Userstorefront.prototype, "insdate", void 0);
__decorate([Transform(dateTransform)], Userstorefront.prototype, "moddate", void 0);
class StorefrontProduct {
  constructor() {
    this.today = new Date().getTime();
  }
  get ispromo() {
    if (this.promobaseprice !== undefined) {
      return this.today >= this.promofrom && this.today <= this.promoto;
    } else return false;
  }
  get promofromasdate() {
    return new Date(this.promofrom);
  }
}
__decorate([Transform(boolTransform)], StorefrontProduct.prototype, "active", void 0);
__decorate([Transform(currencyTransform)], StorefrontProduct.prototype, "baseprice", void 0);
__decorate([Transform(dateTransform)], StorefrontProduct.prototype, "insdate", void 0);
__decorate([Transform(dateTransform)], StorefrontProduct.prototype, "moddate", void 0);
__decorate([Transform(currencyTransform)], StorefrontProduct.prototype, "promobaseprice", void 0);
__decorate([Transform(dateTransform)], StorefrontProduct.prototype, "promofrom", void 0);
__decorate([Transform(dateTransform)], StorefrontProduct.prototype, "promoto", void 0);
class StorefrontComposition {
  constructor() {
    this.today = new Date().getTime();
  }
  get promofromasdate() {
    return new Date(this.promofrom);
  }
  get ispromo() {
    if (this.promodeltaprice !== undefined) {
      return this.today >= this.promofrom && this.today <= this.promoto;
    } else return false;
  }
}
__decorate([Transform(currencyTransform), Expose({
  name: 'baseprice'
})], StorefrontComposition.prototype, "deltaprice", void 0);
__decorate([Transform(currencyTransform)], StorefrontComposition.prototype, "promodeltaprice", void 0);
__decorate([Transform(dateTransform)], StorefrontComposition.prototype, "promofrom", void 0);
__decorate([Transform(dateTransform)], StorefrontComposition.prototype, "promoto", void 0);
__decorate([Transform(boolTransform)], StorefrontComposition.prototype, "active", void 0);
__decorate([Transform(currencyTransform), Exclude({
  toPlainOnly: true
})], StorefrontComposition.prototype, "displayprice", void 0);
__decorate([Transform(currencyTransform), Exclude({
  toPlainOnly: true
})], StorefrontComposition.prototype, "displaypromoprice", void 0);
class StorefrontCountry {
  fromCountry(c) {
    this.countryid = c.id;
    this.home = c.home;
  }
}
__decorate([Transform(boolTransform)], StorefrontCountry.prototype, "home", void 0);
__decorate([Transform(dateTransform)], StorefrontCountry.prototype, "insdate", void 0);
__decorate([Transform(dateTransform)], StorefrontCountry.prototype, "moddate", void 0);
function storefrontcomposition() {
  return StorefrontComposition;
}
function storefrontproduct() {
  return StorefrontProduct;
}
function storefront() {
  return StoreFront;
}
class Stockchange {}
__decorate([Transform(dateTransform)], Stockchange.prototype, "transactiondate", void 0);
class Stockchangereason {}
__decorate([Transform(dateTransform)], Stockchangereason.prototype, "insdate", void 0);
var CountryCode;
(function (CountryCode) {
  CountryCode["ITALIA"] = "IT";
})(CountryCode || (CountryCode = {}));
class Country {
  constructor(name) {
    this.name = name;
  }
  get isItalia() {
    return this.code === CountryCode.ITALIA || this.name === 'Italia';
  }
}
__decorate([Transform(boolTransform)], Country.prototype, "home", void 0);
__decorate([Transform(currencyTransform)], Country.prototype, "duty", void 0);
__decorate([Transform(boolTransform)], Country.prototype, "provincelist", void 0);
__decorate([Transform(boolTransform)], Country.prototype, "comunilist", void 0);
function country() {
  return Country;
}
class I18N {}
var WebArticleMode;
(function (WebArticleMode) {
  WebArticleMode["OFF"] = "off";
  WebArticleMode["ON"] = "on";
})(WebArticleMode || (WebArticleMode = {}));
//@dynamic
class WebArticle {
  get hastext() {
    return this.shorttext && this.shorttext.trim() !== '';
  }
  get haslink() {
    return this.text && this.text.trim() !== '';
  }
}
__decorate([Transform(dateTransform)], WebArticle.prototype, "insdate", void 0);
__decorate([Transform(dateTransform)], WebArticle.prototype, "moddate", void 0);
__decorate([Transform(dateTransform)], WebArticle.prototype, "articledate", void 0);
__decorate([Transform(dateTransform)], WebArticle.prototype, "eventfromdate", void 0);
__decorate([Transform(dateTransform)], WebArticle.prototype, "eventtodate", void 0);
__decorate([Type(() => WebArticleSection)], WebArticle.prototype, "sections", void 0);
__decorate([Type(() => WebArticle)], WebArticle.prototype, "correlated", void 0);
var WebArticleStatus;
(function (WebArticleStatus) {
  WebArticleStatus["ACTIVE"] = "active";
  WebArticleStatus["INACTIVE"] = "inactive";
})(WebArticleStatus || (WebArticleStatus = {}));
var WebArticleType;
(function (WebArticleType) {
  WebArticleType["FULL"] = "full";
  WebArticleType["HALF"] = "half";
  WebArticleType["FULL_VIDEO"] = "fullvideo";
  WebArticleType["HALF_VIDEO"] = "halfvideo";
})(WebArticleType || (WebArticleType = {}));
function webarticle() {
  return WebArticle;
}
//@dynamic
class WebArticleSection {}
__decorate([Exclude({
  toPlainOnly: true
})], WebArticleSection.prototype, "slidermodel", void 0);
__decorate([Transform(dateTransform)], WebArticleSection.prototype, "insdate", void 0);
__decorate([Transform(dateTransform)], WebArticleSection.prototype, "moddate", void 0);
//@dynamic
class WebArticleCorrelated {}
__decorate([Transform(dateTransform)], WebArticleCorrelated.prototype, "insdate", void 0);
__decorate([Transform(dateTransform)], WebArticleCorrelated.prototype, "moddate", void 0);
__decorate([Type(() => WebArticle)], WebArticleCorrelated.prototype, "correlated", void 0);
var WebArticleSectionType;
(function (WebArticleSectionType) {
  WebArticleSectionType["CMS"] = "cms";
  WebArticleSectionType["SLIDER"] = "slider";
})(WebArticleSectionType || (WebArticleSectionType = {}));
var WebArticleCorrelatedType;
(function (WebArticleCorrelatedType) {
  WebArticleCorrelatedType["CORRELATED"] = "correlated";
  WebArticleCorrelatedType["ALTERNATIVE"] = "alternative";
})(WebArticleCorrelatedType || (WebArticleCorrelatedType = {}));
var Utype;
(function (Utype) {
  Utype["PRIVATE"] = "individual";
  Utype["COMPANY"] = "company";
})(Utype || (Utype = {}));
class Newsletter extends FullNamedEntity {
  static {
    this.TABLE = 'newsletter';
  }
}
__decorate([Transform(boolTransform)], Newsletter.prototype, "unsubscribed", void 0);
__decorate([Transform(dateTransform)], Newsletter.prototype, "subscribedate", void 0);
__decorate([Transform(dateTransform)], Newsletter.prototype, "canceldate", void 0);
var TeacherStatus;
(function (TeacherStatus) {
  TeacherStatus["ACTIVE"] = "active";
  TeacherStatus["INACTIVE"] = "inactive";
})(TeacherStatus || (TeacherStatus = {}));
class Teacher extends Staff {
  get fullname() {
    return `${this.fname || ''} ${this.lname || ''}`;
  }
  get initials() {
    let f = '';
    if (this.fname) {
      f = f + this.fname.substring(0, 1);
    }
    if (this.lname) {
      f = f + this.lname.substring(0, 1);
      ;
    }
    if (!f.length && this.email) {
      f = this.email.substring(0, 2);
    }
    if (!f.length) {
      f = 'AN';
    }
    return f;
  }
}
__decorate([Transform(dateTransform)], Teacher.prototype, "insdate", void 0);
__decorate([Transform(dateTransform)], Teacher.prototype, "moddate", void 0);
__decorate([Transform(dateTransform)], Teacher.prototype, "lastaccess", void 0);
__decorate([Exclude()], Teacher.prototype, "plainpassword", void 0);
var DealerStatus;
(function (DealerStatus) {
  DealerStatus["ACTIVE"] = "active";
  DealerStatus["INACTIVE"] = "inactive";
})(DealerStatus || (DealerStatus = {}));
class Dealer {}
__decorate([Transform(dateTransform)], Dealer.prototype, "insdate", void 0);
__decorate([Transform(dateTransform)], Dealer.prototype, "moddate", void 0);
class DealerZone {}
var SortCriteria;
(function (SortCriteria) {
  SortCriteria["SORT_NEW"] = "new";
  SortCriteria["SORT_OLD"] = "old";
  SortCriteria["SORT_PRICE_ASC"] = "priceasc";
  SortCriteria["SORT_PRICE_DESC"] = "pricedesc";
  SortCriteria["SORT_BESTSELLER"] = "bestseller";
  SortCriteria["SORT_RELEVANCE"] = "relevance";
})(SortCriteria || (SortCriteria = {}));
class CatalogSearch {
  constructor() {
    this.attributevalids = [];
  }
  addAttributeFilter(id) {
    if (!this.attributevalids.includes(id)) {
      this.attributevalids.push(id);
    }
  }
  removeAttributeFilter(id) {
    const index = this.attributevalids.findIndex(n => n == id);
    if (index >= 0) {
      this.attributevalids.splice(index, 1);
    }
  }
  resetAttributeFilters() {
    this.attributevalids = [];
  }
  getQueryParams(paramname) {
    const queryparams = {};
    if (this.taxonomyid) {
      queryparams['tid'] = this.taxonomyid;
    }
    if (this.attributevalids?.length) {
      queryparams['attr'] = this.attributevalids.join(':');
    }
    if (!isEqual(queryparams, {}) || this.text) {
      let ret = {};
      ret['t'] = this.text;
      ret[paramname] = btoa(JSON.stringify(queryparams));
      return ret;
    } else {
      return undefined;
    }
  }
  setDataFromParams(params, paramname) {
    if (!params) {
      return;
    }
    this.text = params['t'];
    const paramsbase64 = params[paramname];
    if (paramsbase64) {
      const paramsjson = atob(paramsbase64);
      let params;
      try {
        params = JSON.parse(paramsjson);
      } catch (err) {
        console.log('cannot deserialize params ', paramsjson);
      }
      if (params) {
        if (params && params['tid']) {
          this.taxonomyid = params['tid'];
        } else {
          this.taxonomyid = undefined;
        }
        if (params && params['attr']) {
          this.attributevalids = params['attr'].split(':');
        }
      }
    } else {
      this.resetAttributeFilters();
      this.taxonomyid = undefined;
    }
  }
}
class Seo {}
__decorate([Transform(dateTransform)], Seo.prototype, "insdate", void 0);
__decorate([Transform(dateTransform)], Seo.prototype, "moddate", void 0);
class Metadata {
  get schemadata() {
    const data = {};
    data['@context'] = "https://schema.org/";
    data['@type'] = "Product";
    data.name = this.metatitle;
    data.image = this.image;
    if (this.brand) {
      data.brand = this.brand;
    }
    data.sku = this.sku;
    if (this.gtin8) {
      data.gtin8 = this.gtin8;
    }
    data.offers = {
      "@type": "Offer",
      "url": this.url,
      "priceCurrency": this.currency,
      price: this.price,
      "availability": this.instock ? "https://schema.org/InStock" : "https://schema.org/OutOfStock",
      "itemCondition": "https://schema.org/NewCondition"
    };
    if (this.pricevaliduntil) {
      data.offers['price'] = this.promoprice;
      data.offers['priceValidUntil'] = this.pricevaliduntil;
    }
    return data;
  }
  get is404() {
    return this.httpstatus === '404';
  }
  get is200() {
    return this.httpstatus === '200';
  }
  get metaimage() {
    return this.image;
  }
}
class CopyRequest {}

/*
 * Public API Surface of eli-ui-lib
 */

/**
 * Generated bundle index. Do not edit.
 */

export { Attribute, AttributeInpuType, AttributeRenderType, AttributeType, AttributeVal, Book, BookAttribute, BookInstance, BookResource, BookResourceGroup1, BookResourceGroup2, BookResourceType, BookTaxonomy, CMS, CMSCMSType, CMSCatalogType, CMSSection, CMSToolboxType, CMSType, CatalogSearch, CopyRequest, Correlated, Country, CountryCode, Dealer, DealerStatus, DealerZone, EliUILibModule, EliUser, EliUserStatus, I18N, Images, Metadata, Newsletter, PriceElement, ProductAttributeval, ProductMedia, ProductVendor, RelType, Seo, ShareRequest, SortCriteria, Stockchange, Stockchangereason, StoreFront, StorefrontComposition, StorefrontCountry, StorefrontExtraFeeType, StorefrontProduct, Storefrontlang, Taxonomy, TaxonomyAttribute, Teacher, TeacherStatus, TranslateStatus, TreeViewComponent, Userstorefront, Utype, Variants, Vendor, WebArticle, WebArticleCorrelated, WebArticleCorrelatedType, WebArticleMode, WebArticleSection, WebArticleSectionType, WebArticleStatus, WebArticleType, attribute, attributeval, book, cms, country, parentUndefinedToZero, parentZeroToUndefined, product, productAttribute, productMedia, storefront, storefrontcomposition, storefrontproduct, taxonomy, vendor, webarticle };
