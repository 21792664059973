@if (fullscreen) {
  <div class="loading show"><i class="fa fa-spinner"
       aria-hidden="true"></i>
  </div>
} @else {
 <div class="loading loading-block">
   <i class="fa fa-spinner"
     aria-hidden="true"></i>
 </div>
}
