import { LocationStrategy } from '@angular/common';
import { Directive, ElementRef, HostBinding, Input, OnDestroy, Renderer2, SimpleChanges } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterLinkWithHref } from '@angular/router';
import { SharedDataService } from 'elbuild-ui-lib-core';
import { map, filter, mergeMap } from 'rxjs';
import { pathinlangs } from 'src/conf/pages';
import { environment } from 'src/environments/environment';




@Directive({
  selector: 'a[eliRouterLink]'
})
export class EliRouterLinkWithHref extends RouterLinkWithHref implements OnDestroy {
  lang: string = environment.lang;

  @HostBinding('class')
  class: string;

  currenturl: string;
  url: string;

  constructor(private shareddataservice: SharedDataService, router: Router, route: ActivatedRoute,
    locationStrategy: LocationStrategy, renderer: Renderer2, el: ElementRef) {
    super(router, route, undefined, renderer, el, locationStrategy);

    this.currenturl = router.url;
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => route))
      .pipe(map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }))
      .pipe(filter(route => route.outlet === 'primary'))
      .pipe(mergeMap(route => route.data))
      .subscribe(event => {
        this.currenturl = router.url;
        this.setActiveClass();
      });
  }
  @Input()
  eliRouterLink: any[] | string;


  ngOnChanges(changes: SimpleChanges) {
    if ((changes['eliRouterLink'] && changes['eliRouterLink'].previousValue != changes['eliRouterLink'].currentValue)) {
      this.setupUrl();
      super.ngOnChanges(changes);
    }
  }


  ngOnDestroy(): void {

  }

  setupUrl() {

    let url = [];
    const commands = this.eliRouterLink;
    if (!Array.isArray(commands)) {
      url = commands.split('/');
    } else {
      url = commands;
    }

    const translatedurl = [];
    for (const u of url) {
      if (pathinlangs[u] && pathinlangs[u][this.lang]) {
        translatedurl.push(pathinlangs[u][this.lang])
      } else {
        translatedurl.push(u);
      }
    }



    this.url = translatedurl.join('/');
    this.routerLink = this.url;


    this.setActiveClass();
  }


  setActiveClass() {
    if (this.currenturl && this.url && this.currenturl === this.url) {
      this.class = 'active';
    } else {
      this.class = '';
    }
  }

}
