@if (products?.length>=2) {
  <section class="related">

    <div class="container">
      <div class="row align-items-center">
        <div class="col">
          <div class="subsection-title">{{"related" | translate}}</div>
          <div class="carousel {{products?.length>2 ? 'col-lg-12': 'col-lg-6'}}">
            <app-carousel [items]="products"
                          [maxVisible]="4"
                          [numScroll]="1"></app-carousel>


            <a class="btn btn-primary"
               eliRouterLink="/catalog"
               [queryParams]="{taxo: taxonomyid}">
              {{'view-all-products' | translate}}
              <i class="ms-3  fa fa-long-arrow-right"
                 aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
}
