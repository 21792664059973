export const environment = {
  production: true,
  relativeapiurl: '/ws/',
  siteurl: 'https://demo.elipublishing.com',
  apiurl: 'http://eli_eli-api:8080/eli-api/v1/',
  sitetitle: 'ELi Publishing',
  storefront: 'https://demo.elipublishing.com',
  lang: 'en',
  template: 'default',
  cacheablepages: ['/'],
  cacheactive: true,
  cacheprefix: 'elienssr_',
  cacheredis: true,
  cacheexpire: 60 * 30, //seconds
  redisport: 6379,
  redishost: 'eli_redis',
  sitedowntitle: 'SITE TEMPORARILY UNAVAILABLE',
  cdnurl: 'https://d2xfnexjhce5on.cloudfront.net/',
  cdnforlder: 'eli',
  inactivepages: ['request'],
  sitename: 'ELi Publishing',
  sitedescription: 'ELi Publishing',
  sitecover: 'https://demo.elipublishing.com/assets/images/logos/en.svg',
  requestcopy: false,
  itregisterlink: 'https://sdpd.elionline.com/docenti/register?lang=it&ref=https%3A%2F%2Fwww.gruppoeli.it',
  privacypolicy: 'https://www.iubenda.com/privacy-policy/58178485',
  cookiepolicy: 'https://www.iubenda.com/privacy-policy/58178485/cookie-policy',
  youtubeplaylist: 'https://www.youtube.com/playlist?list=PLl39wYd8yUAafkRD0ZEjYJR-qm2P0P8tE',
  facebook: 'https://www.facebook.com/ELiPublishingELT',
  externalnetwork: undefined,
  webinarvideo: undefined,
  corporatebrochure: undefined,
  teacherguide: 'https://d2xfnexjhce5on.cloudfront.net/eli/taxonomy/94/syllabus/ELT_readers.pdf',
  syllabus: 'https://d2xfnexjhce5on.cloudfront.net/eli/taxonomy/94/syllabus/ELi%20Graded%20readers%20Syllabus_ELT.pdf'

};


