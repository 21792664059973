import { Component } from '@angular/core';
import { SharedDataService, CrudService } from 'elbuild-ui-lib-core';
import { Country, Newsletter, Teacher } from 'eli-ui-lib';
import { environment } from 'src/environments/environment';
import { CustomerService } from '../../services/customer.service';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.' + environment.template + '.html',
  styleUrl: './newsletter.component.scss'
})
export class NewsletterComponent {

  newsletter: Newsletter = new Newsletter();
  loggeduser: Teacher;
  message: string;
  success: boolean;
  countries: Country[];


  constructor(private sharedDataService: SharedDataService,
    private customerservice: CustomerService,
    private crudservice: CrudService) {
    this.sharedDataService.currentLoggedUser.subscribe((data: any) => {
      this.loggeduser = data as Teacher;
      if (this.loggeduser) {
        this.newsletter.fname = this.loggeduser.fname;
        this.newsletter.lname = this.loggeduser.lname;
        this.newsletter.email = this.loggeduser.email;
        this.newsletter.subject = this.loggeduser.subject;
      }
    })
    this.loadCountries();

  }


  loadCountries() {

    this.crudservice.getAll(Country, 'country').subscribe({
      next: (data: Country[]) => {
        this.countries = data;
      },
      error: (err: any) => {
        console.error(err);
      }
    })
  }
  submit() {
    this.message = undefined;
    this.customerservice.newsletter(this.newsletter).subscribe({
      next: (n: Newsletter) => {
        this.newsletter = new Newsletter();
        this.message = 'newsletter-success';
        this.success = true;
      },
      error: (err: any) => {
        if (err.error.msg === 'email-already-in-newsletter') {
          this.message = 'email-already-in-newsletter';
        } else {
          this.message = 'newsletter-error';
        }
        this.success = false;
      }
    })


  }
}
