import { Component, Input, OnInit } from '@angular/core';
import { Book, Taxonomy } from 'eli-ui-lib';
import { CatalogService } from 'src/app/core/services/catalog.service';

@Component({
  selector: 'app-product-related',
  templateUrl: './product-related.component.html',
  styleUrl: './product-related.component.scss'
})
export class ProductRelatedComponent implements OnInit {
  taxonomyid: number;

  @Input()
  set product(p: Book) {
    if (p) {
      this.load(p);
    }
  }


  @Input()
  set taxonomy(t: Taxonomy) {
    if (t) {
      this.loadTaxonomy(t);
      this.taxonomyid = t.id;
    }
  }


  products: Book[];

  constructor(private catalogservice: CatalogService) {
  }


  ngOnInit(): void {

  }


  load(p: Book) {

    if (p.activetaxonomies?.length == 1) {
      this.taxonomyid = p.activetaxonomies[0].id;
    }

    this.catalogservice.getBookCorrelated(p.id).subscribe({
      next: (data: Book[]) => {
        this.products = data;
      },
      error: (err: any) => {
        console.error(err);
      }
    })
  }

  loadTaxonomy(p: Taxonomy) {
    this.catalogservice.getTaxonomyProducts(p.id, 20).subscribe({
      next: (data: Book[]) => {
        this.products = data;
      },
      error: (err: any) => {
        console.error(err);
      }
    })
  }

}
