import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CMS } from 'eli-ui-lib';
import { CatalogService } from '../../services/catalog.service';


// YFCOMMERCE COMPONENT
@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  host: { ngSkipHydration: 'true' },
  styleUrls: ['./cms.component.scss']
})
export class CmsComponent implements OnInit {

  @Input()
  slug: string;

  @Input()
  id: number;

  @Input()
  background: boolean = true;

  @Input()
  showLoading: boolean = false;

  @Input()
  useCDN: boolean = true;

  @Input()
  folder: string = 'cms';

  loading: boolean;

  cms: CMS;

  @Input()
  size = 'xl';

  @Output() loaded: EventEmitter<any> = new EventEmitter<any>();



  constructor(

    private ecommerceService: CatalogService,
  ) { }

  ngOnInit() {
    this.loading = true;
    this.loadCMS()


  }

  imageloaded() {
    this.loaded.emit();
  }

  loadCMS(): void {

    if (this.slug) {
      this.ecommerceService.getCmsBySlug(this.slug).subscribe((data: CMS) => {
        this.cms = data;
        this.loading = false;
      }, error => {
        console.error('Error loading CMS', this.slug, error);
        this.loading = false;
      });
    }
  }

}
