import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NewsletterComponent } from './components/newsletter/newsletter.component';
import { SocialComponent } from './components/social/social.component';
import { BannerComponent } from './components/banner/banner.component';
import { TranslateModule } from '@ngx-translate/core';
import { ElbuildCoreComponentsModule } from 'elbuild-ui-componentslib';
import { CatalogItemComponent } from './components/catalog-item/catalog-item.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ScrollManagerDirective } from './directives/scroll-manager.directive';
import { ScrollSectionDirective } from './directives/scroll-section.directive';
import { ScrollAnchorDirective } from './directives/scroll-anchor.directive';
import { CmsBackgroundDirective } from './directives/cms-background.directive';
import { CmsComponent } from './components/cms/cms.component';
import { ImagePictureComponent } from './components/image-picture/image-picture.component';
import { AsyncBackgroundGradientDirective } from './directives/async-background-gradient.directive';
import { ProductRelatedComponent } from './components/product-related/product-related.component';
import { CarouselModule } from 'primeng/carousel';
import { EliLoaderComponent } from './components/eli-loader/eli-loader.component';
import { EliRouterLinkWithHref } from './directives/eli-router-link';
import { IssuuViewerComponent } from './components/issuu-viewer/issuu-viewer.component';
import { CarouselComponent } from './components/carousel/carousel.component';



@NgModule({
  declarations: [
    BannerComponent,
    NewsletterComponent,
    SocialComponent,
    CatalogItemComponent,
    SafeHtmlPipe,
    ScrollManagerDirective,
    ScrollSectionDirective,
    ScrollAnchorDirective,
    CmsBackgroundDirective,
    CmsComponent,
    EliLoaderComponent,
    ImagePictureComponent,
    AsyncBackgroundGradientDirective,
    ProductRelatedComponent,
    EliRouterLinkWithHref,
    IssuuViewerComponent,
    CarouselComponent
  ],
  exports: [
    CarouselComponent,
    IssuuViewerComponent,
    BannerComponent,
    NewsletterComponent,
    SocialComponent,
    CatalogItemComponent,
    SafeHtmlPipe,
    ScrollManagerDirective,
    ScrollSectionDirective,
    ScrollAnchorDirective,
    CmsBackgroundDirective,
    CmsComponent,
    ImagePictureComponent,
    AsyncBackgroundGradientDirective,
    ProductRelatedComponent,
    EliLoaderComponent,
    EliRouterLinkWithHref
  ],
  imports: [
    RouterModule,
    CommonModule,
    TranslateModule,
    ElbuildCoreComponentsModule,
    CarouselModule,
    FormsModule
  ]
})
export class CoreModule { }
