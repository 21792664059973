import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CatalogService } from '../../services/catalog.service';
import { CMS } from 'eli-ui-lib';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  host: { ngSkipHydration: 'true' },
  styleUrl: './banner.component.scss'
})
export class BannerComponent implements OnInit {


  @Input()
  text: string = '';


  @Input()
  cmsslug: string;


  @Input()
  title: string = '';
  @Input()
  buttontext: string = '';
  @Input()
  routerlink: string | undefined;
  @Input()
  href: string | undefined;
  @Input()
  imageopaque: string | undefined;
  @Input()
  imageurl: string | undefined;
  @Input()
  imageasbackground: boolean = false;
  @Input()
  layout: 'half' | 'full' = 'half';
  @Input()
  mediaslug: string;

  @Input()
  imageposition: 'top' | 'bottom' = 'top';

  @Output() onaction: EventEmitter<any> = new EventEmitter<any>();

  loading: boolean = true;
  mediacms: CMS;

  constructor(private catalogservice: CatalogService) {

  }
  ngOnInit(): void {
    if (this.mediaslug) {

      this.catalogservice.getCmsBySlug(this.mediaslug).subscribe({
        next: (data: CMS) => {
          this.mediacms = data;
          this.loading = false;
        },
        error: (error: any) => {
          console.error('error', error);
          this.loading = false;
        }
      });
    } else {
      this.loading = false;
    }
  }

  click() {
    this.onaction.emit();
  }



}
