<ng-container *ngIf="contentready">

  <source *ngIf="cdn"
          (ready)="callready($event, 'webp')"
          asyncimg
          [opaque]="opaque"
          [size]="imgsize"
          [base]="base"
          [cdn]="cdnurl"
          [id]="eid"
          [folder]="folder"
          cdntype="webp"
          field="srcset"
          type="image/webp">

  <source *ngIf="cdn"
          (ready)="callready($event, 'png')"
          asyncimg
          [opaque]="opaque"
          [size]="imgsize"
          [base]="base"
          [cdn]="cdnurl"
          [id]="eid"
          [folder]="folder"
          cdntype="png"
          field="srcset"
          type="image/png">

  <img (ready)="callready($event, 'fallback')"
       asyncimg
       [opaque]="opaque"
       [size]="imgsize"
       [class]="class"
       [base]="base"
       [cdn]="cdnurl"
       [id]="eid"
       [folder]="folder"
       [placeholder]="placeholder"
       [alt]="alt">

</ng-container>