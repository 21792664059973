import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { ListItem } from 'elbuild-ui-lib-core';
import { Newsletter, Teacher } from 'eli-ui-lib';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  httpOptions: any;

  constructor(protected http: HttpClient) { }


  signup(teacher: Teacher): Observable<any> {
    return this.http.post(`/ws/access/teacher/signup`, instanceToPlain(teacher))
      .pipe(map(res => plainToInstance(Teacher, res)));
  }


  resetPassword(email: string): Observable<any> {
    return this.http.post(`/ws/access/teacher/resetpwd`, { email });

  }


  newsletter(newsletter: Newsletter): Observable<any> {
    return this.http.post(`/ws/newsletter`, instanceToPlain(newsletter))
      .pipe(map(res => plainToInstance(Newsletter, res)));
  }

  getList(slug: string): Observable<ListItem[]> {
    return this.http.get(`/ws/selistitem/slug/${slug}`)
      .pipe(map(res => plainToInstance(ListItem, res) as unknown as ListItem[]));
  }

  validateEmailToken(token: string): Observable<any> {

    return this.http.get(`/ws/access/resetpwd/${token}`);

  }

  sendNewPassword(uid: number, password: string, token: string): Observable<any> {

    return this.http.post(`/ws/access/${uid}/password`, {
      password, token
    })


  }





}
