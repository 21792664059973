<section class="newsletter">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <span class="box-title">{{"newsletter-title" | translate}}</span>
        <p class="box-text">{{"newsletter-text" | translate}}</p>
      </div>
      <div class="col-lg-6">
        @if (message) {
          <span class="{{success ? 'success': 'error'}}">{{message | translate}}</span>
        }
        @if(!success){
          <form class="newsletter-form"
                #editForm="ngForm"
                (ngSubmit)="editForm.valid && submit()">
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group ">
                  <label for="exampleInputPassword1">{{"firstname" | translate}}*</label>
                  <input #firstname="ngModel"
                         required
                         name="fname"
                         [(ngModel)]="newsletter.fname"
                         type="text"
                         class="form-control"
                         id="fname"
                         [ngClass]="{'is-invalid': editForm.submitted && firstname.invalid}"
                         [placeholder]="'insert-first-name' | translate">
                  <div class="invalid-feedback"
                       *ngIf="editForm.submitted && firstname.invalid">
                    {{ 'error-required' | translate }}
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group ">
                  <label for="lastname">{{"surname" | translate}}*</label>
                  <input #lastname="ngModel"
                         required
                         name="lastname"
                         [(ngModel)]="newsletter.lname"
                         type="text"
                         class="form-control"
                         id="lastname"
                         [ngClass]="{'is-invalid': editForm.submitted && lastname.invalid}"
                         [placeholder]="'insert-last-name' | translate">
                  <div class="invalid-feedback"
                       *ngIf="editForm.submitted && lastname.invalid">
                    {{ 'error-required' | translate }}
                  </div>
                </div>
              </div>
              <div class="form-group col-lg-12">
                <label for="country">{{"country" | translate}}*</label>
                <select #country="ngModel"
                        required
                        name="country"
                        [(ngModel)]="newsletter.country"
                        [ngClass]="{'is-invalid': editForm.submitted && country.invalid}"
                        class="form-select"
                        id="country">
                  <option selected
                          [value]="undefined">{{"select-option" | translate}}</option>
                  @for (c of countries; track c.id) {
                    <option [value]="c.sigla">
                      {{c.name}}
                    </option>
                  }
                </select>
                <div class="invalid-feedback"
                     *ngIf="editForm.submitted && passwordf.invalid">
                  {{ 'error-required' | translate }}
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label for="emailaddress">{{"emailaddress" | translate}}*</label>
                  <input #email="ngModel"
                         required
                         name="email"
                         [(ngModel)]="newsletter.email"
                         type="email"
                         class="form-control"
                         id="emailaddress"
                         [ngClass]="{'is-invalid': editForm.submitted && email.invalid}"
                         [placeholder]="'insert-email' | translate">
                  <div class="invalid-feedback"
                       *ngIf="editForm.submitted && email.invalid">
                    {{ 'error-required' | translate }}
                  </div>
                </div>
              </div>
              <div class="form-group col-lg-12">
                <label for="insterested">{{"insterested" | translate}}*</label>
                <app-list-items-selector [multiselect]="true"
                                         #subject="ngModel"
                                         entity="selistitem"
                                         parent="selist"
                                         [emptyOption]="true"
                                         [list]="'teacher.subject'"
                                         [translatelabels]="true"
                                         required
                                         [ngClass]="{'is-invalid': editForm.submitted && subject.invalid}"
                                         name="subject"
                                         [(ngModel)]="newsletter.subject">
                </app-list-items-selector>
                <div class="invalid-feedback"
                     *ngIf="editForm.submitted && subject.invalid">
                  {{ 'error-required' | translate }}
                </div>
              </div>
              <div class="col-12 ">
                <button class="btn btn-primary mt-4"
                        type="submit">{{"subscribe" | translate}} <i class="ms-3 icon icon-arrowlong"
                     aria-hidden="true"></i></button>
              </div>

            </div>
          </form>
        }
      </div>
    </div>
  </div>
</section>
