<p-carousel
	[value]="items"
	[numVisible]="numVisible"
	[numScroll]="numScroll"
	[showNavigators]="shownav"
	[showIndicators]="false"
	[circular]="true">
	<ng-template
		let-item
		pTemplate="item">
		<div class="carousel-item">
			<app-catalog-item [item]="item"></app-catalog-item>
		</div>

	</ng-template>
</p-carousel>
