import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Book, CMS, Taxonomy, WebArticle } from 'eli-ui-lib';
import { environment } from 'src/environments/environment';
import { CatalogService } from '../../services/catalog.service';

@Component({
  selector: '[app-image-picture]',
  templateUrl: './image-picture.component.html',
  styleUrls: ['./image-picture.component.scss']
})
export class ImagePictureComponent implements OnInit, OnChanges, AfterViewInit {


  @Output() ready = new EventEmitter<boolean>();

  // per usare la cdn serve cdn, folder, base, eid, opaque

  cdn: boolean = environment.cdnurl !== undefined;
  cdnurl: string = environment.cdnurl;
  base: string = environment.cdnforlder;



  @Input()
  class: string;

  @Input()
  alt: string;

  @Input()
  folder: string;



  @Input()
  eid: number;

  @Input()
  opaque: string;

  @Input()
  size: string = 'md';

  @Input()
  placeholder: string;

  @Input()
  book: Book;

  @Input()
  cms: CMS;

  @Input()
  taxonomy: Taxonomy;

  @Input()
  webarticle: WebArticle;

  @Input()
  slug: string;

  @Input()
  field: string;

  cdnurls: any = {};
  contentready: any;




  isready = { png: false, webp: false, fallback: false };



  constructor(private catalogservice: CatalogService) {


  }

  callready(event: any, type: string) {
    this.isready[type] = true;

    if (this.isready.fallback && (!this.cdn || this.isready.png || this.isready.webp)) {
      this.ready.emit(event);
    }

  }

  ngOnInit(): void {

  }


  ngAfterViewInit(): void {
    if (this.slug) {
      this.loadCms();
    } else {

      this.setContent();
    }


  }

  ngOnChanges() {

    if (this.slug) {

    } else {
      this.setContent();
    }

  }

  loadCms() {
    this.catalogservice.getCmsBySlug(this.slug).subscribe({
      next: (data: CMS) => {
        if (data?.media) {
          this.cms = data;
          this.setContent();
        }
      },
      error: (error: any) => {
        console.error('error', error);
      }
    });

  }


  setContent() {

    if (this.taxonomy) {
      this.eid = this.cdn ? this.taxonomy.id : undefined;
      this.folder = 'taxonomy';
      this.opaque = this.field ? this.taxonomy[this.field] : this.taxonomy.cover;
      this.alt = this.alt || this.taxonomy.title;

    }
    else if (this.book) {

      this.eid = this.cdn ? this.book.id : undefined;
      this.folder = 'book';
      this.opaque = this.field ? this.book[this.field] : this.book.cover;
      this.alt = this.alt || this.book.title;

    }
    else if (this.cms) {

      this.eid = this.cdn ? this.cms.id : undefined;
      this.folder = 'cms';
      this.opaque = this.field ? this.cms[this.field] : this.cms.media;
      this.alt = this.alt || this.cms.alt;

    }
    else if (this.webarticle) {
      this.eid = this.cdn ? this.webarticle.id : undefined;
      this.folder = 'webarticle';
      this.opaque = this.webarticle.media;
      this.alt = this.alt || this.webarticle.title;
    }

    if (!this.alt) {
      this.alt = '';
    }
    setTimeout(() => {
      this.contentready = true;
    }, 0);
  }

  get imgsize() {
    return this.size === undefined || this.size === 'full' ? undefined : this.size;
  }



}
