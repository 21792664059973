<section class="social-boxes">
  <div class="container">
    <div class="row">
      <!--in spagnolo niente facebook-->
      @if (lang !== 'es') {
        <div class="col">
          <span class="circle"><i class="fa fa-facebook"
               aria-hidden="true"></i></span>
          <div class="out-of-circle">

            <h3>{{"fb-title" | translate}}</h3>
            <p>{{ "fb-text" | translate}}</p>

            @if (fburl) {
              <a class="btn btn-light"
                 [href]="fburl"
                 target="_blank">{{"facebook-cta"| translate}} <i class="ms-3 icon icon-arrowlong"
                   aria-hidden="true"></i>
              </a>
            } @else {
              <a class="btn btn-light">{{"facebook-cta"| translate}} <i class="ms-3 icon icon-arrowlong"
                   aria-hidden="true"></i>
              </a>
            }
          </div>
        </div>
      }
      <div class="col">
        <span class="circle">
          <i class="fa fa-youtube-play"
             aria-hidden="true"></i>
        </span>
        <div class="out-of-circle">
          <h3>{{"yt-title" | translate}}</h3>
          <p>{{ "yt-text" | translate}}</p>
          <a class="btn btn-light"
             [href]="youtubelink"
             target="_blank">{{"social-cta"| translate}} <i class="ms-3 icon icon-arrowlong"
               aria-hidden="true"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
