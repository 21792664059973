import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-eli-loader',
  templateUrl: './eli-loader.component.html',
  styleUrl: './eli-loader.component.scss'
})
export class EliLoaderComponent {

  @Input()
  fullscreen: boolean;
}
