<a eliRouterLink="/p/{{item.url}}">

  @if (item.insdate >=newmininsdate) {
    <span class="new"> {{'new-product' | translate}}</span>
  }
  @if (item.cover) {
    <div class="image-frame">
      <picture app-image-picture
               [book]="item"
               size="md"
               class="image img-fluid"></picture>
    </div>
  }

  <div class="details">
    <span class="title">{{item.title}}</span>
    <span class="desc">
      {{((item.subtitle1Html || '') + (item.subtitle2Html || '')) | truncate:50}} </span>
  </div>
</a>