<div *ngIf="cms && !loading"
     class="cms-block slug-{{cms.slug}}">
  <h4 *ngIf="cms.showtitle">{{cms.title}}</h4>

  <div class="asyncbgauto"
       style="height: 200px;"
       *ngIf="cms.media && background"
       asyncbg
       (ready)="imageloaded()"
       [size]="size"
       [opaque]="cms.media"></div>

  <picture *ngIf="cms.media && !background"
           app-image-picture
           [cms]="cms"
           [size]="size"
           (ready)="imageloaded()"
           class="img-fluid"></picture>

  <p [innerHTML]="cms.text | sanitizer"></p>
</div>

<div class="loading"
     *ngIf="loading && showLoading">
  <i class="fa fa-circle-o-notch fa-spin fa-2x"></i>
</div>