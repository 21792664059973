<div asyncbg
     *ngIf="!loading"
     [size]="'lg'"
     [opaque]="imageasbackground ? mediacms?.media : undefined"
     [placeholder]="!mediacms?.media && imageasbackground ? imageurl: undefined"
     class="banner {{layout}}"
     [ngClass]="{'imageasbackground': imageasbackground, 'hasimage': imageurl}">

  @if (imageposition === 'top') {
    <ng-template *ngTemplateOutlet="image"></ng-template>
  }
  <div class="central">
    @if (title) {
      <span class="box-title">{{title | translate}}</span>
    }
    <p class="box-text">
      @if(!cmsslug){
        {{text | translate}}
      } @else {
        <app-cms [slug]="cmsslug"></app-cms>
      }
    </p>
    @if (imageposition === 'bottom') {
      <div>
        <ng-template *ngTemplateOutlet="image"></ng-template>
      </div>
    }
    @if (routerlink) {
      <a (click)="click()"
         [eliRouterLink]="routerlink"
         [href]="href"
         class="btn btn-primary">
        {{buttontext | translate}} <i class="ms-3 icon icon-arrowlong"
           aria-hidden="true"></i>
      </a>
    }
  </div>
</div>



<ng-template #image>

  @if(!imageasbackground && imageurl && !mediacms?.media){
    <img class="img-fluid"
         [src]="imageurl" />

  } @else if(!imageasbackground && mediacms?.media) {
    <picture app-image-picture
             [cms]="mediacms"
             size="xl"
             class="img-fluid"></picture>
  }


</ng-template>
