export const pathinlangs: Record<string, Record<string, string>> = {
  toolbox: {
    'en': 'toolbox',
    'de': 'toolbox',
    'es': 'caja-de-recursos',
    'it': 'toolbox',
    'fr': 'toolbox',
    'chrula': 'toolbox'
  },
  catalog: {
    'en': 'catalog',
    'de': 'katalog',
    'es': 'catalogo',
    'it': 'catalogo',
    'fr': 'catalogue',
    'chrula': 'catalog'
  }
  ,
  contacts: {
    'en': 'contacts',
    'de': 'kontakte',
    'es': 'contactos',
    'it': 'contatti',
    'fr': 'contacts',
    'chrula': 'contacts'
  },
  login: {
    'en': 'login',
    'de': 'anmeldung',
    'es': 'iniciar-sesion',
    'it': 'login',
    'fr': 'connexion',
    'chrula': 'login'
  },
  network: {
    'en': 'sales-network',
    'de': 'vertriebspartner',
    'es': 'distribuidores',
    'it': 'distributori',
    'fr': 'distributeurs'
  },
  webinar: {
    'en': 'webinar',
    'de': 'webinar',
    'es': 'webinario',
    'it': 'webinar',
    'fr': 'webinaire'
  },
  students: {
    'en': 'students',
    'de': 'lernende',
    'es': 'estudiante',
    'it': 'studente',
    'fr': 'apprenant'
  },
  teacher: {
    'en': 'teacher',
    'de': 'lehrende',
    'es': 'profesorado',
    'it': 'docente',
    'fr': 'enseignant'
  },
  signup: {
    'en': 'signup',
    'de': 'registrierung',
    'es': 'registrarse',
    'it': 'crea-account',
    'fr': 'inscription',
    'chrula': 'signup'
  },
  request: {
    'en': 'sample-copy',
    'de': 'ansichtsexemplar',
    'es': 'muestras',
    'it': 'copia-saggio',
    'fr': 'copie-specimen'
  },
  about: {
    'en': 'about-us',
    'de': 'uber-uns',
    'es': 'acerca-de-nosotros',
    'it': 'chi-siamo',
    'fr': 'a-propos'
  }
  ,
  download: {
    'en': 'pdf-download',
    'de': 'pdf-download',
    'es': 'descarga-pdf',
    'it': 'dowload-pdf',
    'fr': 'pdf-telechargement',
  },
  awards: {
    'en': 'awards',
    'de': 'awards',
    'es': 'awards',
    'it': 'awards',
    'fr': 'awards'
  },
  equilibrium: {
    'en': 'equilibrium',
    'de': 'equilibrium',
    'es': 'equilibrium',
    'it': 'equilibri',
    'fr': 'equilibrium'
  },
  reallives: {
    'en': 'real-lives',
    'de': 'real-lives',
    'es': 'real-lives',
    'it': 'real-lives',
    'fr': 'real-lives',
  }
}
